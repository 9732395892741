import model from './model';
import {
  bookingsBookItClick,
  bookingsContactInfoSaveSuccess,
} from '@wix/bi-logger-wixboost-ugc/v2';
import { components } from './components';
import {
  biReportBookInitialIntentSrc16Evid33,
  biReportSlotSelectionCompletedSrc16Evid34,
} from '../../biEvents';
import { Item } from 'wix-ui-tpa/dist/types/components/Grid/Item/Item';

export default model.createController(
  ({ $bind, $widget, $w, flowAPI, controllerConfig }) => {
    const biLogger = flowAPI.essentials.biLoggerFactory().logger();

    const setRepeaterData = (array) => {
      console.log('Got inner repeater data:', array);
      $w(components.repeater).data = array;
    };

    return {
      pageReady: async () => {
        // console.log('Loaded Inner repeater');
        $widget.fireEvent('widgetLoaded', {});

        $w(components.repeater).onItemReady(async ($item, data, index) => {
          $item(components.slotButton).label = data.date;
          $item(components.slotButton).link = generateButtonLink(
            data,
            controllerConfig,
          );
          $item(components.slotButton).target = '_self';
          $item(components.slotButton).expand();

          $item(components.slotButton).onClick((event) => {
            console.log('Slot button clicked:', event);
            const $item = $w.at(event.context);
            console.log('$item:', $item);
            const itemData = $w(components.repeater).data.find(
              (element) =>
                `${element._id}__${element.externalId}` ===
                event.context.itemId,
            );
            console.log('ItemData:', itemData);
            const itemSlot = itemData.slots[0];
            $widget.fireEvent('buttonClicked', {});
            biReportBookingsBookItClick(biLogger);
            biReportBookingsContactInfoSaveSuccess(biLogger);
            biReportBookInitialIntentSrc16Evid33(
              biLogger,
              itemSlot.serviceId,
              itemSlot.serviceType,
              true,
            );
            biReportSlotSelectionCompletedSrc16Evid34(
              biLogger,
              itemSlot.serviceId,
              itemSlot.serviceType,
              itemSlot.startDate,
              itemSlot.endDate,
            );
          });
        });
      },
      exports: {
        setRepeaterData,
      },
    };
  },
);

const biReportBookingsBookItClick = (biLogger) => {
  // BI 16:501
  biLogger.report(
    bookingsBookItClick({
      widget_name: 'next_available_widget',
    }),
  );
};

const biReportBookingsContactInfoSaveSuccess = (biLogger) => {
  // BI 16:517
  biLogger.report(
    bookingsContactInfoSaveSuccess({
      serviceId: '3f41bb42-3b1e-4f02-9176-166c9f9b96af',
      pageName: 'next_available_widget',
    }),
  );
};

const generateButtonLink = (data, controllerConfig) => {
  const slots = data.slots;
  // console.log('slots for link:', slots);
  const randomNumber = Math.floor(Math.random() * data.slots.length);
  // console.log('randomNumber:', randomNumber);
  const selectedSlot = slots[randomNumber];
  // console.log('selectedSlot:', selectedSlot);

  const url = selectedSlot.sessionId
    ? createDeepLinkForClass(controllerConfig, selectedSlot)
    : createDeepLinkForAppointment(controllerConfig, selectedSlot);
  // console.log('url:', url);
  return url;
};

function createDeepLinkForAppointment(controllerConfig, selectedSlot) {
  let url = `${
    controllerConfig.wixCodeApi.location.baseUrl
  }/booking-form?referral=next_available_widget&bookings_resourceId=${
    selectedSlot.resource.id
  }&bookings_startDate=${encodeURIComponent(
    selectedSlot.startDate,
  )}&bookings_endDate=${encodeURIComponent(
    selectedSlot.endDate,
  )}&bookings_serviceId=${selectedSlot.serviceId}&bookings_timezone=${
    selectedSlot.timezone
  }`;
  if (!selectedSlot.location.locationType.includes('CUSTOM')) {
    if (selectedSlot.location.id !== undefined) {
      const locationUrl = `bookings_locationId=${selectedSlot.location.id}`;
      url = `${url}&${locationUrl}`;
    }
  }
  return url;
}

function createDeepLinkForClass(controllerConfig, selectedSlot) {
  const url = `${controllerConfig.wixCodeApi.location.baseUrl}/booking-form?referral=next_available_widget&bookings_sessionId=${selectedSlot.sessionId}&bookings_timezone=${selectedSlot.timezone}`;
  return url;
}
